import './topbar.css'
import { NavLink } from 'react-router-dom';

export default function Topbar() {
  return (
    <div>
        <div className="topbarContainer">
            <div className="topLeft">
                <NavLink to="/" className="myName" activeClassName="activeLink">
                    <img src="/assets/logo.png" alt="" className="logo" />Jasvith Chand Anne
                </NavLink>
            </div>
            <div className="topMid"></div>
            <div className="topRight">
                <div className="topRightlinks">
                    <NavLink to="/" className="topRightlinkItem" activeClassName="activeLink">Workspace</NavLink>
                    <NavLink to="/blog" className="topRightlinkItem" activeClassName="activeLink">My Blog</NavLink>
                    <NavLink to="/about" className="topRightlinkItem" activeClassName="activeLink">About Me</NavLink>
                </div>
            </div> 
        </div>
    </div>
  )
}
