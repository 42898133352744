import React from 'react'
import Intro from '../../components/intro/Intro'
import Workspace from '../../components/workspace/Workspace'



export default function Home() {
  return (
    <>
    <Intro/>
    <Workspace/>
    </>
  )
}
